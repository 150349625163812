import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout blog={true}>
      <div className="contact full-page-module">
        <h1>Contact</h1>
        <p>Contact <a href="mailto:refreshsystems1@gmail.com?Subject=Hey Refresh Team"> refreshsystems1@gmail.com</a> if you have feedback or questions. We'll do our best to reply within 48 hours. </p>
      </div>
    </Layout>
  )
}
